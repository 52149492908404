<template>
  <div
    ref="root"
    class="relative border-[0.5px] border-transparent rounded-[12px] overflow-hidden cursor-pointer"
    @click="onClick"
  >
    <video
      ref="video"
      disablepictureinpicture
      muted
      loop
      preload="auto"
      :width="width"
      :height="height"
      :poster="poster"
    >
      <source
        v-for="src of sources"
        :src="src"
        :type="`video/${src.split('.').at(-1)}`"
      />
    </video>

    <div
      class="absolute inset-0 flex items-center justify-center border-[0.5px] border-[#383838] rounded-[12px]"
      :class="{
        'opacity-100': !isPlaying,
        'opacity-0': isPlaying,
      }"
    >
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          data-figma-bg-blur-radius="39"
          cx="50"
          cy="50"
          r="49"
          fill="url(#paint0_linear_78_1909)"
          stroke="url(#paint1_linear_78_1909)"
          stroke-width="0.5"
        />
        <path
          d="M60 48.2679C61.3333 49.0377 61.3333 50.9623 60 51.7321L46.5 59.5263C45.1667 60.2961 43.5 59.3338 43.5 57.7942L43.5 42.2058C43.5 40.6662 45.1667 39.7039 46.5 40.4737L60 48.2679Z"
          fill="white"
        />
        <defs>
          <clipPath id="bgblur_0_78_1909_clip_path">
            <circle transform="translate(38.25 38.25)" cx="50" cy="50" r="49" />
          </clipPath>
          <linearGradient
            id="paint0_linear_78_1909"
            x1="66.4198"
            y1="1"
            x2="66.6419"
            y2="98.9995"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-opacity="0.35" />
            <stop offset="1" stop-opacity="0.85" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_78_1909"
            x1="101.032"
            y1="36.9093"
            x2="53.9021"
            y2="-29.4952"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4200FF" />
            <stop offset="1" stop-color="#3A3A3A" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

defineProps<{
  poster: string;
  width: number;
  height: number;
  sources: string[];
}>();

const root = ref<HTMLElement | null>(null);
const video = ref<HTMLVideoElement | null>(null);

const isPlaying = ref(false);
function onClick() {
  if (video.value == null) {
    return;
  }

  if (isPlaying.value) {
    video.value.pause();
    video.value.currentTime = 0;
    isPlaying.value = false;
  } else {
    video.value.play();
    isPlaying.value = true;
  }
}
</script>
